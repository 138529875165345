export const localStorageKeys = {
    RECENT_SEARCHES: 'RECENT_SEARCHES',
};

export const cookieStorageKeys = {
    CURRENCY_COOKIE: 'currency',
    LOCALE: 'NEXT_LOCALE',

    SET_COOKIE_DOMAIN: process.env.NEXT_PUBLIC_SET_COOKIE_DOMAIN as string,
    END_USER_AUTH_KEY: process.env.NEXT_PUBLIC_END_USER_AUTH_KEY as string,
    CMS_TENANT_KEY: process.env.NEXT_PUBLIC_CMS_TENANT_KEY as string,
    CMS_WORKGROUP_KEY: process.env.NEXT_PUBLIC_CMS_WORKGROUP_KEY as string,
    CMS_AUTH_KEY: process.env.NEXT_PUBLIC_CMS_AUTH_KEY as string,
    CMS_AUTH_REFRESH: process.env.NEXT_PUBLIC_CMS_AUTH_REFRESH as string,
    CMS_CUSTOMER_KEY: process.env.NEXT_PUBLIC_CMS_CUSTOMER_KEY as string,
};
export const sessionStorageKeys = {
    BOOKING_FORM: 'BOOKING_FORM',
};
