import Cookies from 'js-cookie';

export function setClientCookie({
    name,
    value,
    options,
}: {
    name: string;
    value: string;
    options?: Cookies.CookieAttributes;
}) {
    Cookies.set(name, value, options);
}

export function getClientCookie(name: string): string | undefined {
    return Cookies.get(name);
}

export function removeClientCookie({ name, options }: { name: string; options?: Cookies.CookieAttributes }) {
    Cookies.remove(name, options);
}
