'use client';
import { cookieStorageKeys } from '@/constants/storageKeys';
import { getClientCookie } from '@/utils/client-cookie';
import { Button } from '@heroui/button';
import { Image } from '@heroui/image';
import { useDisclosure } from '@heroui/use-disclosure';
import { BiMenuAltRight } from '@react-icons/all-files/bi/BiMenuAltRight';
import { IoGlobeOutline } from '@react-icons/all-files/io5/IoGlobeOutline';
import { RiMoneyCnyCircleLine } from '@react-icons/all-files/ri/RiMoneyCnyCircleLine';
import { RiMoneyDollarCircleLine } from '@react-icons/all-files/ri/RiMoneyDollarCircleLine';
import dynamic from 'next/dynamic';
import NextImage from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode, useEffect } from 'react';

const UserDropdown = dynamic(() => import('../UserDropdown'));
const MobileDrawer = dynamic(() => import('./MenuMobile/MobileDrawer'));
const CurrencySwitcherDrawer = dynamic(() => import('@/components/layout/CurrencySwitcherDrawer'));
const LanguageSwitcherDrawer = dynamic(() => import('../LanguageSwitcherDrawer'));
const ServiceDropdown = dynamic(() => import('@/components/layout/ServiceDropdown'));
const CompanyDropdown = dynamic(() => import('@/components/layout/CompanyDropdown'));
const SolutionDropdown = dynamic(() => import('@/components/layout/SolutionDropdown'));
const LibraryDropdown = dynamic(() => import('@/components/layout/LibraryDropdown'));
const LanguageAndCurrencySwitcher = dynamic(() => import('@/components/layout/LanguageAndCurrencySwitcher'));

type Props = {};
const currencies: Array<{
    key: string;
    label: string;
    icon: ReactNode;
}> = [
    {
        key: 'VND',
        label: 'currency.vietnamDong',
        icon: <div className='font-semibold leading-[18px]!'>₫</div>,
    },
    { key: 'USD', label: 'currency.usDollar', icon: <RiMoneyDollarCircleLine size={18} /> },
    { key: 'CNY', label: 'currency.chineseYuan', icon: <RiMoneyCnyCircleLine size={18} /> },
];
const Header = (props: Props) => {
    // region hooks
    // const { isMobile } = useDeviceDetect();
    const pathName = usePathname();
    const { isOpen, onClose, onOpenChange } = useDisclosure();
    const { isOpen: isOpenCurrency, onClose: onCloseCurrency, onOpenChange: onOpenChangeCurrency } = useDisclosure();
    const { isOpen: isOpenLanguage, onClose: onCloseLanguage, onOpenChange: onOpenChangeLanguage } = useDisclosure();
    const currentCurrency = getClientCookie(cookieStorageKeys.CURRENCY_COOKIE) || 'VND';

    // region effects
    useEffect(() => {
        onClose();
        onCloseCurrency();
        onCloseLanguage();
    }, [pathName]);

    return (
        <header className='py-5 bg-white z-30 relative'>
            <div className='mx-auto max-w-screen-xl px-4 sm:px-3 lg:px-4'>
                <nav className='relative z-50 flex justify-between'>
                    <div className='flex items-center md:gap-x-12'>
                        <Link aria-label='Home' href='/'>
                            <span className='text-xl text-primary font-bold'>
                                <Image
                                    as={NextImage}
                                    alt='vnlogi-logo'
                                    radius='none'
                                    src='/assets/images/VNLOGI_logo-cropped.svg'
                                    className='w-full h-full'
                                    width={150}
                                    height={28}
                                />
                            </span>
                        </Link>
                    </div>
                    <div className='flex items-center gap-x-4 md:gap-x-3'>
                        <div className='hidden lg:flex md:gap-x-3'>
                            <ServiceDropdown />
                            <SolutionDropdown />
                            <LibraryDropdown />
                            <CompanyDropdown />
                        </div>

                        <div className='lg:hidden flex gap-2'>
                            <span
                                className='flex items-center font-semibold cursor-pointer text-sm text-slate-700 px-2 py-1 hover:text-primary bg-slate-100  transition-all duration-200 rounded-full'
                                onClick={() => onOpenChangeCurrency()}
                            >
                                <span className='me-1'>{currencies.find((i) => i.key === currentCurrency)?.icon}</span>(
                                {currentCurrency})
                            </span>
                            <span
                                className='flex items-center font-semibold cursor-pointer text-sm text-slate-700 px-2 py-1 hover:text-primary bg-slate-100  transition-all duration-200 rounded-full'
                                onClick={() => onOpenChangeLanguage()}
                            >
                                <IoGlobeOutline size={18} />
                            </span>
                        </div>

                        <div className='hidden lg:flex'>
                            <LanguageAndCurrencySwitcher />
                        </div>

                        <div className='hidden lg:flex'>
                            {/* <UserContextProvider> */}
                            <UserDropdown isMainLayout />
                            {/* </UserContextProvider> */}
                        </div>

                        <div className='-mr-1 lg:hidden'>
                            <Button
                                onPress={() => {
                                    onOpenChange();
                                }}
                                variant='light'
                                isIconOnly
                                size='sm'
                            >
                                <BiMenuAltRight size={22} />
                            </Button>
                        </div>
                    </div>
                </nav>
            </div>

            <MobileDrawer
                isOpenMenu={isOpen}
                onClose={() => {
                    onOpenChange();
                }}
            />

            <CurrencySwitcherDrawer
                isOpenCurrency={isOpenCurrency}
                onClose={() => {
                    onOpenChangeCurrency();
                }}
                currencies={currencies}
            />

            <LanguageSwitcherDrawer isOpenLanguage={isOpenLanguage} onClose={() => onOpenChangeLanguage()} />
        </header>
    );
};

export default Header;
